<template>
  <v-container fluid class="home">
    <notification-popup
      :dialog="notifSuccess"
      :contentMsg="$t('TicketHasBeenAddCart')"
      :headerMsg="$t('Success')"
      :nameBtnNo="$t('ViewAnotherTicket')"
      :nameBtnYes="$t('ViewCart&CheckOut')"
      :imgSource="'transactions/success.svg'"
      colorIcon="success"
      typeModal="dialog"
      Icon="mdi-check-circle"
      :outlinedBorderBtnNo="false"
      :onHandlerYes="buttonOke"
      :onHandlerNo="buttonNo"
    />

    <notification-popup
      :dialog="notifFailed"
      :contentMsg="$t('notifTicketFailedAdd')"
      :headerMsg="$t('Failed')"
      :nameBtnNo="$t('labelNo')"
      :nameBtnYes="$t('TryAgain')"
      disableBtnNo="true"
      colorIcon="danger"
      typeModal="transaction"
      Icon="mdi-close-circle"
      :onHandlerYes="buttonOkeFailed"
    />

    <div v-if="userRole.toUpperCase().endsWith('-BLACKLIST')">
      <v-row
        justify="center"
        class="null-item"
      >
        <v-col class="d-flex justify-center pb-0" cols="10">
          <v-img
            class="image"
            :src="require('../../assets/bus-homepage.svg')"
          ></v-img>
        </v-col>
        <v-col class="d-flex justify-center py-0 mt-4" cols="12">
          <h2 class="notification">{{ $t("SuspendWarning") }}</h2>
        </v-col>
      
      </v-row>
    </div>
    <div v-else>
      <RouteInformation
        :routeTitle="itemRoute.name"
        :keyItem="itemRoute.fareID"
        :dialog="routeInformationDialog"
        :expiredNote="itemRoute.expiredNote"
        :fare-rule="itemRoute.fareRule"
        :price="itemRoute.price"
        :currency-code="itemRoute.currencyCode"
        :route-loc="itemRoute.routeLoc"
      />
      <v-row v-if="item.length===0 && isTicketLoaded" justify="center" class="center-no-ticket p-0">
        <v-col class="d-flex justify-center p-0" cols="12">
          <v-img
            :src="require('@/assets/icon/no-ticket.svg')"
          ></v-img>
        </v-col>
        <v-col class="text-center p-0">
          <h3 class="font-weight-bold">{{ $t("NoTicket") }}</h3>
        </v-col>
      </v-row>
      <ListofTicketMultiTrips
        v-else
        :item="item"
        :clickRouteInformation="clickRouteInformation"
        :togglebuy="togglebuy"
        :reset="reset"
      />
      <v-row class="d-flex justify-center mt-3" v-if="eof && hasScroll">
        <b>{{ $t("Eof") }}</b>
      </v-row>
    </div>
    <v-footer v-if="$partnerACL.isShowButton('cart') && !userRole.toUpperCase().endsWith('-BLACKLIST')" fixed app width="auto" color="white" elevation="3">
      <v-container>
        <v-row class="pa-0 ma-0">
          <v-col cols="12" align-self="center">
            <ac-button 
              :active="$partnerACL.isShowButton('cart')"
              :name="$t('AddToCart')" 
              :on-click-handler="()=>addToCart(item)"
              :is-disabled="buttonDisabled"
              width="100%"
            />
          </v-col>
        </v-row>
      </v-container>
    </v-footer>
  </v-container>
</template>

<script>
import store from "@/store";
import NotificationPopup from "@/components/NotificationPopup.vue";
// import CardBuyTicket from "@/components/CardBuyTicket.vue";
import RouteInformation from "@/components/RouteInformationMulti.vue";
import ListofTicketMultiTrips from "@/components/ListofMultitrips.vue";
import AcButton from "@/components/AcButton.vue";
import moment from "moment";
export default {
  name: "FlateFare",
  components: { RouteInformation, NotificationPopup, ListofTicketMultiTrips, AcButton },
  data() {
    return {
      searchticket: "",
      routeInformationDialog: false,
      notifSuccess: false,
      notifFailed: false,
      selected: [],
      item: [],
      page: 0,
      limit: 20,
      bottom: false,
      isEnd: false,
      buttonDisabled: false,
      customerID: store.state.auth.userData.customerID,
      reset: false,
      eof: false,
      lastPage: 2,
      hasScroll: false,
      isTicketLoaded: false,
    };
  },
  computed: {
    itemRoute() {
      return this.$store.state.moduleUi.routeInformation;
    },
    userRole() {
      return this.$store.state.auth.user_role;
    }
  },
  watch: {
    bottom(bottom) {
      if (bottom & !this.eof) {
        this.addData();
      }
    },
    itemRoute() {
      if (this.itemRoute == "") {
        this.routeInformationDialog = false;
      } else {
        this.routeInformationDialog = true;
      }
    },
    item: {
      handler: function () {
        if (this.item.filter((item) => item.is_buy == true).length > 0) {
          this.buttonDisabled = false;
        } else {
          this.buttonDisabled = true;
        }
      },
      deep: true,
    },
    buttonDisabled() {
      let data = this.item.filter((item) => item.is_buy == true);
      if (data.length < 0) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    convertPriceByCurrency(price, currency) {
      const conversionRates = this.$store.state.purchaseticket.currency ? this.$store.state.purchaseticket.currency.conversionRates : null;
      const priceResult = price * conversionRates[currency];
      return priceResult;
    },
    getExpiredNote(fareRule) {
      return fareRule.expired_on_same_day ? 'ExpiredOnSameDay' :
        fareRule.valid_days != null && !fareRule.expired_on_same_day ? 'ExpiredAfterPurchase' :
          fareRule.expired_after_usage ? 'ExpiredAfterUsage' :
            fareRule.validate_close_date == null ? 'Unlimited' :
              'ValidationPeriod'
    },
    bottomVisible() {
      const scrollY = window.scrollY;
      const visible = document.documentElement.clientHeight;
      const pageHeight = document.documentElement.scrollHeight;
      const bottomOfPage = visible + scrollY >= pageHeight - 10;
      return bottomOfPage || pageHeight < visible;
    },
    refreshCart() {
      const payload = {
        customerID: this.customerID,
      };
      this.$store
        .dispatch("purchaseticket/getQtyCart", payload)
        .catch((err) => {
          console.log(err);
        });
      this.item = this.item.map((el) => {
        var o = Object.assign({}, el);
        o.is_buy = false;
        return o;
      });
    },
    togglebuy(value, index) {
      this.item[index].is_buy = value;
    },
    clickRouteInformation() { },
    addToCart(items) {
      this.selected = [];
      this.selected = items.filter((items) => items.is_buy == true);

      this.selected = items.filter((item) => item.is_buy == true).map(fare => {
        return {
          fareID: fare.fareID,
          fareType: fare.type,
          itemName: fare.name,
          qty: fare.cart_qty,
          currencyCode: fare.currencyCode,
          price: fare.price,
        }
      });

      const payload = {
        customerID: this.customerID,
        item: this.selected,
      };
      this.$store
        .dispatch("purchaseticket/addtocart", payload)
        .then((response) => {
          this.notifSuccess = true;
          this.refreshCart();
          this.item = this.item.map(fare => {
            return {
              ...fare,
              cart_qty: 0,
              is_buy: false
            }
          })
          if (response.status) console.log("success");
        })
        .catch((err) => {
          // this.notifFailed = true;
          console.log(err);
        });
    },
    buttonOke() {
      this.notifSuccess = false;
      if (this.notifSuccess == false) this.toCartPage();
    },
    buttonNo() {
      this.notifSuccess = false;
      this.reset = !this.reset;
      // if (this.notifSuccess == false) this.$router.push({ name: "Cart" });
    },
    buttonOkeFailed() {
      this.notifFailed = false;
      // if (this.notifFailed == false) this.$router.push({ name: "PaymentMethodAdd" });
    },
    toCartPage() {
      // this.$router.push({ name: "Cart" });
      const cartButton = this.$partnerACL.getMetaButton('cart');
      if (cartButton) {
        if (cartButton.menu_type == "wsc_menu") {
          this.$router.push({ path: cartButton.redirect_path });
        } else {
          window.location = cartButton.redirect_path;
        }
      } else {
        this.$router.push({ name: "Cart" })
      }
    },
    addData() {
      this.page = this.page + 1;
      var payload = {
        page: this.page,
        limit: this.limit,
      };
      if (this.page <= this.lastPage) {
        this.$store
          .dispatch("purchaseticket/getMultiTrips", payload)
          .then((response) => {
            this.isTicketLoaded = true;
            this.item = this.item.concat(response.data.map(fare => {
              return {
                ...fare,
                priceStr: fare.price.toString(),
                convertedPrice: this.convertPriceByCurrency(fare.price, fare.currencyCode),
                selectedCurrency: this.$store.state.purchaseticket.currency ? this.$store.state.purchaseticket.currency.code : "",
                expiredNote: this.getExpiredNote(fare.fareRule),
                validate_open_date: fare.fareRule.validate_open_date ? moment.utc(fare.fareRule.validate_open_date).format("DD-MM-YYYY") : "",
                validate_close_date: fare.fareRule.validate_close_date ? moment.utc(fare.fareRule.validate_close_date).format("DD-MM-YYYY") : ""
              }
            }))

            this.lastPage = response.lastPage
            this.item = this.item.map((el) => {
              var o = Object.assign({}, el);
              o.is_buy = o.is_buy || false;
              return o;
            });
            if (response.page == response.lastPage) {
              this.isEnd = true;
            }
            if (response.page == response.lastPage) {
              this.eof = true;
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
  },
  destroyed() {
    // window.removeEventListener("touchmove", () => {
    //   this.bottom = this.bottomVisible();
    // });
    // window.removeEventListener("scroll", () => {
    //   this.bottom = this.bottomVisible();
    // });
  },
  async created() {
    // window.addEventListener("scroll", () => {
    //   this.bottom = this.bottomVisible();
    // });

    // window.addEventListener("touchmove", () => {
    //   this.bottom = this.bottomVisible();
    // });
    const payloadCurrency = {
      currencyCode: localStorage.getItem('currency') || "IDR",
    };
    await this.$store.dispatch("purchaseticket/getCurrencyConversionRate", payloadCurrency)
  },
  mounted() {
    window.addEventListener("scroll", () => {
      this.hasScroll = true
      this.addData()
    });
    this.addData();
  },
};
</script>
<style lang="scss" scoped>
.center-no-ticket {
  position: absolute;
  top: 45%;
  /* position the top  edge of the element at the middle of the parent */
  left: 55%;
  /* position the left edge of the element at the middle of the parent */
  transform: translate(-50%, -50%);
  /* This is a shorthand of
                                         translateX(-50%) and translateY(-50%) */
}

/* If the screen size is 601px or more, set the font-size of <div> to 80px */
@media only screen and (min-width: 601px) {
  .home {
    background: #f5fff7;
  }

  .class-search-ticket {
    background: #f5fff7;
    margin-bottom: -10px;
  }

  .grid {
    display: grid;
  }

  .search-ticket {
    width: 100%;
    padding-left: 30%;
  }

  .footer {
    margin-top: 5%;
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 70px;
    text-align: center;
    background: #ffffff;
    mix-blend-mode: normal;
    /* Field */
    box-shadow: 0px 1px 10px 0px;
  }

  .text-center {
    align-content: center;
  }
}

/* If the screen max size is 600px, set the font-size of <div> to 80px */
@media only screen and (max-width: 600px) {
  .home {
    background: #f5fff7;
  }

  .class-search-ticket {
    margin: 10px;
    background: #fdfdfd;
    margin-bottom: -10% !important;
  }

  .grid {
    display: grid;
  }

  .search-ticket {
    width: 100%;
  }

  .footer {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    text-align: center;
    background: #ffffff;
    mix-blend-mode: normal;
    /* Field */

    box-shadow: 0px 1px 10px 0px;
  }

  .text-center {
    align-content: center;
  }

  .null-item {
    position: absolute;
    top: 46%;
    right: 50%;
    transform: translate(46%, -50%);
  }

  .notification {
    text-align: center;
  }
}
</style>