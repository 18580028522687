<template>
  <v-row>
    <!-- Jangan dikasih margin, nanti bisa jadi ga fixed navbar nya -->
    <v-col
      class="pb-1"
      cols="12"
      no-gutters
      v-for="(item, index) in item"
      :key="index"
    >
      <CardBuyTicket
        :currency-code="item.selectedCurrency"
        :fare-id="item.fareID"
        :fare-type="item.type"
        :price="item.convertedPrice"
        :fare-name="item.name"
        :max-trip="item.fareRule.max_trip"
        :max-trip-per-day="item.fareRule.max_trip_perday"
        :valid-days="item.fareRule.valid_days"
        :click-information="clickRouteInformation"
        :route-information="item"
        :expired-after-usage="item.fareRule.expired_after_usage"
        :reset="reset"
        v-model="item.cart_qty"
        @buy="togglebuy($event, index)"
      />
    </v-col>
  </v-row>
</template>

<script>
import CardBuyTicket from "@/components/CardBuyMultiTrips.vue";
// import { component } from 'vue/types/umd';

export default {
  props: ["item", "clickRouteInformation", "togglebuy", "reset"],
  components: { CardBuyTicket },
};
</script>

<style scoped>
.col {
}
</style>