<template>
  <v-row align="center" justify="center">
    <v-col cols="10" class="content pa-0 ma-0">
      <v-card
        elevation="1"
        :class="
          fareType == 5 ? 'card-content-student' : 'card-content-multi'
        "
      >
        <v-row align="center" class="ma-0 pa-0">
          <v-col class="ma-0 pa-0" cols="8">
            <v-row align="center" justify="center" class="ma-0 pa-0 pt-1">
              <v-col class="d-flex justify-center pa-0 ma-0" cols="12">
                <div class="rule-1">
                  <!-- <span v-if="expiredAfterUsage != 0" style="text-transform: lowercase;">{{ expiredAfterUsage }} {{$t("Days")}}, </span> -->
                  <!-- <p class="mb-0">Multi day ticket is valid for 30 days</p> -->

                  <!-- <span v-if="maxTrip == 0" style="text-transform: lowercase;">{{$t("UnlimitedTrips")}} </span>
                   <span v-else style="text-transform: lowercase;">{{ maxTrip }} {{$t("TripsPerYear")}} </span> -->
                  <span><b>{{ $t("BusTicket") }} </b></span>
                </div>
                <v-icon
                  v-on:click="clickRouteInformation()"
                  class="pl-1"
                  x-small
                  >mdi-information-outline
                </v-icon>
              </v-col>
            </v-row>
            <v-row align="center" class="ma-0 pa-2 pb-0 pt-0">
              <v-col
                class="ma-0 pa-0 d-flex text-center justify-center"
                cols="12"
              >
                <span class="pl-1 bth font-weight-bold">
                  <!-- {{ $t("Evoucher") }} {{ fareName }} -->
                  {{ fareName }}
                </span>
              </v-col>
            </v-row>
            <v-row align="center" justify="center" class="ma-0 pa-0 pt-0">
              <v-col class="d-flex justify-center pa-0 ma-0" cols="12">
                <!-- <div v-if="maxTripPerDay == 0" class="rule-2">
                  &#9679; {{$t("UnlimitedDays")}} &#9679;
                </div>
                <div v-else class="rule-2">
                  &#9679; {{ maxTripPerDay }} {{$t("Trips_day")}} &#9679;
                </div> -->
                <div class="rule-2">
                  &#9679;
                  <span v-if="maxTrip == 0">
                    {{$t("UnlimitedTrips")}}</span
                  >
                  <span v-else-if="$i18n.locale == 'vi' && maxTrip != 0">
                    Tập {{ maxTrip }} vé</span
                  >
                  <span v-else>{{ maxTrip }} trips</span>
                  &#9679;
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="ma-0 pa-0 d-flex justify-center">
                <p class="num-135000-v-nd">
                  {{ Intl.NumberFormat('de-DE', { style: 'currency', currency: currencyCode }).format(lastprice) }} 
                </p>
              </v-col>
            </v-row>
          </v-col>
          <img class="divider" :src="require('@/assets/divider-ticket.svg')" />
          <v-col class="d-flex justify-center col-kanan" cols="4">
            <div class="class-btn-buy" v-if="buy == false">
              <ac-button 
                :small="true"
                :name="$t('Buy')" 
                :on-click-handler="clickBuy"
                :width="'70px'"
              />
            </div>
            <div v-if="buy == true" class="group-18785" :style="`border:1px solid ${$partnerACL.getButtonColor()}`">
              <ac-button 
                :fab="true"
                :outlined="true"
                class-btn="btn-plus"
                :name="'+'" 
                :on-click-handler="mpplus"
              />
              <ac-button 
                :fab="true"
                :outlined="true"
                class-btn="btn-minus"
                :name="'-'" 
                :on-click-handler="mpminus"
              />
              <input class="label input-label" v-model="newValue" disabled :style="`color:${$partnerACL.getButtonColor()}`" />
            </div>
          </v-col>
        </v-row>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import moment from "moment";
import AcButton from "@/components/AcButton";

export default {
  name: "CardBuyTicket",
  components: {AcButton},
  lastprice: "",
  props: {
    price: { 
      type: Number, 
      default: 0 
    },
    currencyCode: { 
      type: String, 
      default: "" 
    },
    fareID: { 
      type: String, 
      default: "-" 
    },
    fareType: { 
      type: Number, 
      default: 3 
    },
    fareName: { 
      type: String, 
      default: "fareName" 
    },
    maxTrip: { 
      type: Number, 
      default: 0 
    },
    maxTripPerDay: { 
      type: Number, 
      default: 0 
    },
    validDays: { 
      type: Number, 
      default: 0 
    },
    expiredAfterUsage: { 
      type: Number, 
      default: 0 
    },
    routeInformation: {
      type: Object,
    },
    clickInformation: {
      /*  set handler function after click oke/ yes
       */
      type: Function,
      required: false,
    },
    value: {
      default: 0,
      type: Number,
    },
    min: {
      default: 1,
      type: Number,
    },
    max: {
      default: undefined,
      type: Number,
    },
    newValues: { 
      type: Number, 
      default: 0 },
    reset: { 
      type: Boolean, 
      default: false },

    keyItem: { 
      type: String },
  },
  data() {
    return {
      // end_date="",
      newValue: this.newValues,
      buy: this.reset,
    };
  },
  methods: {
    clickRouteInformation() {
      this.$store.commit("moduleUi/ROUTE_INFORMATION", this.routeInformation);
      this.clickInformation();
    },
    clickBuy() {
      this.buy = true;
      this.$emit("buy", true);
      this.mpplus();
    },
    mpplus: function () {
      if (this.max === undefined || this.newValue < this.max) {
        this.newValue = this.newValue + 1;
        this.$emit("input", this.newValue);
        this.lastprice = this.price * this.newValue;
      }
    },
    mpminus: function () {
      if (this.newValue > this.min) {
        this.newValue = this.newValue - 1;
        this.$emit("input", this.newValue);
        this.lastprice = this.price * this.newValue;
      } else {
        this.buy = false;
        this.newValue = this.newValue - 1;
        this.$emit("input", this.newValue);
        this.$emit("buy", false);
        this.lastprice = this.price;
      }
    },
  },

  watch: {
    value: {
      handler: function (newVal) {
        this.newValue = newVal;
      },
      validate_end_date() {
        this.validate_end_date = moment(
          this.validate_end_date,
          "DD-MM-YYYY"
        ).format("DD/MM/YYYY");
      },
    },
    reset() {
      this.buy = false;
      this.newValue = 0;
    },
  },
  created: function () {
    this.newValue = this.value;
    this.lastprice = this.price;
  },
};
</script>

<style lang="scss" scoped>
.title-head {
  font-size: 24px;
  color: black;
  text-transform: uppercase;
}
.title-head:first-letter {
  text-transform: uppercase;
}

.divider {
  border: none;
  width: 2px;
  height: 140px;
}
.v-card.theme--light.v-sheet {
  // position: absolute;
  // border: 0.5px solid #c5c5c5;
  overflow: hidden;
}
.v-application .elevation-1 {
  box-shadow: 0px 1px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12) !important;
}
.col-kanan {
  height: 100%;
}

.halfcircle {
  background: #ffffff;
  width: 12.1px;
  height: 22.1px;
  position: absolute;
  top: 45%;
  border: 1px solid black;
  overflow: hidden;
  z-index: 5;
}

.cut-out {
  border-radius: 50px;
  width: 100%;
  height: 20%;
  display: block;
  position: absolute;
  top: 40%;
  background: #fdfdfd;
}

.halfcircle-1 {
  left: -97%;
  box-shadow: inset -1px 2px 2px -1px #888;
}
.halfcircle-2 {
  right: -97%;
  box-shadow: inset 1px 1px 1px 1px #888;
}
.flex-grid {
  display: flex;
}
.col {
  flex: 1;
}
.crop-kiri {
  margin-right: 0%;
  height: 200px;
}
.crop-kanan {
  margin-left: -10%;
  height: 200px;
}
.card-content-student {
  // width: 90%;
  border: 2px solid #53c1ff;
  margin: 0;
  border-radius: 10px;
  max-height: 25%;
}
.card-content-multi {
  // width: 90%;
  border: 2px solid #f29900;
  margin: 0;
  border-radius: 10px;
  max-height: 25%;
}
.content {
  margin-bottom: -12px;
}
.component-1 {
  float: left;
  width: 100%;
  position: relative;
}
.btn-buy-hijau {
  width: 100%;
}
.content-ticket {
  position: absolute;
  top: 70%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  color: white;
  padding: 1em;
}
.background-ticket {
  display: block;
  width: 100%;
}
.rule-1 {
  color: rgba(83, 83, 83);
  font-size: 14px;
}
.rule-2 {
  color: rgba(83, 83, 83);
  font-size: 10px;
}
.flex-wrapper-three {
  margin-top: -10px;
  padding: 0 0 0 0px;
  display: flex;
  align-items: center;
}
.bth {
  font-size: 12px;
  margin: 0;
  padding: 0;
  color: #535353;
}

.relative-wrapper-one {
  margin-right: 15px;
  position: relative;
}
.vector {
  margin-top: 10px;
  width: 80%;
  height: auto;
}
.group {
  position: absolute;
  left: 55px;
  top: -1px;
  transform: rotate(180deg);
}
.flex-wrapper-four {
  margin-left: 10px;
  margin-bottom: -40px;
  // padding: 0 0 0 21px;
  // display: flex;
  // align-items: flex-start;
}
.bn-thanh {
  // @include roboto-10-light;
  margin-top: -15px;
  font-size: 9px;
  color: rgba(75, 177, 78, 1);
  right: 122px;
}
.place-desc {
  font-size: 10px;
  color: rgba(75, 177, 78, 1);
  white-space: nowrap;

  margin-top: -3px;
  margin-bottom: 0px;
  text-overflow: ellipsis;
  padding-bottom: 0;
}
.vector-7 {
  width: 100%;
  margin-bottom: 9px;
}
.flex-wrapper-five {
  padding: 0 0 0 17px;
  display: flex;
  align-items: flex-start;
}
.flex-wrapper-six {
  margin-right: 43px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.route-information {
  // @include roboto-12-medium;
  font-size: 8px;
  color: rgba(94, 215, 153, 1);
  text-decoration: underline;
  margin-top: 0px;
  margin-left: 25%;
  margin-bottom: -2px;
}
.flat-fare-ticket-is-valid-for-24-hours {
  // @include roboto-8-light;
  font-size: 8px;
  margin-left: 25%;
  color: rgba(75, 177, 78, 1);
  font-style: italic;
}
.num-135000-v-nd {
  // @include roboto-14-medium;
  font-size: 12px;
  font-weight: 700;
  margin-bottom: 5px;
  color: rgba(242, 153, 0, 1);
}
.group-18785 {
  border-radius: 23px;
  height: 31px;
  width: 80px;
  padding: 5px 6px 2px 4px;
  position: relative;
  // border: 1px solid rgba(75, 177, 78, 0.35);
}

.class-btn-buy {
  height: 20px;
  // border-radius: 23px;
  // height: 29px;
  // padding: 2px 6px 2px 4px;
  // border: 1px solid rgba(75, 177, 78, 0.35);
  // position: absolute;
  // width: 76px;
  // height: 29px;
  // left: 300px;
  // top: 535px;

  // background: #4BB14E;
  /* Button */

  // box-shadow: 0px 6px 2px -4px rgba(14, 14, 44, 0.1), inset 0px -1px 0px rgba(14, 14, 44, 0.1);
}
.flex-wrapper-one {
  background-color: #ffffff;
  border-radius: 50%;
  padding: 11px 10px 10px 10px;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  position: absolute;
  left: 41px;
  top: -1px;
  border: 1px solid rgba(75, 177, 78, 1);
}
.flex-wrapper-two {
  background-color: #ffffff;
  border-radius: 50%;
  padding: 11px 10px 10px 10px;
  display: flex;
  height: 30px;
  width: 30px;
  align-items: center;
  position: absolute;
  left: -10px;
  top: -1px;
  border: 1px solid rgba(75, 177, 78, 1);
}
.hyphen {
  width: 5px;
  height: 2px;
  background-color: rgba(75, 177, 78, 1);
}
.label {
  width: 64px;
  // @include roboto-10-bold;
  color: rgb(12, 12, 12);
  text-align: center;
  position: relative;
}
.btn-plus {
  top: -5px;
  height: 30px !important;
  width: 30px !important;
  font-size: 20px;
  // border: 1px solid rgba(75, 177, 78, 1);
  z-index: 2;
  // background-color: #ffffff !important;
  // color: rgb(3, 3, 3);
  left: 45px;
}
.btn-minus {
  top: -5px;
  height: 30px !important;
  width: 30px !important;
  // background-color: #ffffff !important;
  // color: rgb(8, 8, 8);
  // border: 1px solid rgba(75, 177, 78, 1);
  z-index: 2;
  font-size: 20px;
  left: -35px;
}
.icon-plus {
  height: 6px;
  width: 1px;
  left: -1px;
}
.icon-minus {
  height: 6px;
  width: 1px;
  left: -1px;
}
.input-label {
  // left: 4px;
  top: -30px;
}
.onHover {
  cursor: pointer;
}
</style>